<template>
  <Button
    v-wave="!props.disabled"
    unstyled
    :class="[colorMode, 'iconButton', props.class]"
    v-bind="$attrs"
    :disabled="props.disabled"
    :style="[{ 'border-color': borderColor }]"
  >
    <ClientOnly v-if="props.iconName">
      <div class="relative">
        <span
          v-if="props.badgeNumber"
          class="p-badge p-component p-badge-no-gutter"
        >
          {{ props.badgeNumber }}</span
        >
        <AppIcon
          :name="props.iconName"
          :size="iconSize"
          v-badge="1"
          :color="iconColor"
        />
      </div>
    </ClientOnly>
  </Button>
</template>

<script lang="ts" setup>
import type { ColorsKey } from '~/types';

const appConfigStore = useAppConfigStore();
const { colorMode } = storeToRefs(appConfigStore);

const props = defineProps({
  iconName: {
    type: String,
  },
  iconColor: {
    type: String as PropType<ColorsKey>,
    default: 'contrast',
  },
  badgeNumber: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  iconSize: {
    type: String,
    default: '24',
  },
  class: {
    type: [String, Array, Object] as PropType<
      string | string[] | Record<string, boolean>
    >,
    required: false,
    default: '',
  },
});

// sooner or later it has to be implemented to show proper buttons mobile vs dekstop
// const computedStyle = computed(() => {
//   return {
//     color: colorMode.value === 'light' ? '#515c66' : '#cacfdb',
//   };
// });

const borderColor = computed(() => {
  if ((props.iconColor as ColorsKey) in allColors[appConfigStore.colorMode]) {
    let color =
      allColors[appConfigStore.colorMode][props.iconColor as ColorsKey];

    if (appConfigStore.colorMode === 'dim') {
      color += '40';
    }

    return color;
  }

  return props.iconColor;
});
</script>

<style scoped>
button {
  background-color: unset;
  border-color: unset;
  border-style: unset;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

button:disabled {
  cursor: default;
  opacity: 0.3;
}

button:disabled:hover {
  background-color: unset;
}

.p-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 5;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
}

.light {
  color: #ffffff;
}
.dim {
  color: #1e232c;
}

.light:hover {
  background-color: rgba(232, 241, 248, 0.7);
}
.dim:hover {
  background-color: #495771;
}
</style>
